@tailwind base;
@tailwind components;
@tailwind utilities;

.tw-scrollbar-none::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
  }
  
  .tw-scrollbar-none {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }